import {
    HSearch,
    HSearchClear,
    HSearchClose,
    HSearchHistory,
    HSearchHistoryLoadMore,
    HSearchHistoryRemove,
    HSearchHistorySelect,
    HSearchInput,
    HSearchMessage,
    HSearchProducerContent,
    HSearchProductContent,
    HSearchResults,
    HSearchSubmit,
    HSearchSuggestionContent
} from '@shoper/phoenix_design_system';

requestAnimationFrame(() => {
    const components = [
        HSearch,
        HSearchInput,
        HSearchHistory,
        HSearchResults,
        HSearchSubmit,
        HSearchClear,
        HSearchClose,
        HSearchMessage,
        HSearchHistoryRemove,
        HSearchHistorySelect,
        HSearchHistoryLoadMore,
        HSearchSuggestionContent,
        HSearchProductContent,
        HSearchProducerContent
    ];
});
